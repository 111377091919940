<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ $t('modulos.conversor_unidade.titulos.listagem') }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.valor"
        class="col-12 col-md-4"
        :label="$t('modulos.conversor_unidade.formulario.valor')"
        obrigatorio
        type="number"
      />
      <input-unidade-medida
        v-model="form.UnidadeMedidaDeId"
        class="col-12 col-md-4"
        :label="$t('modulos.conversor_unidade.formulario.unidade_medida')"
        :multiplos="false"
        obrigatorio
      />
      <input-select
        v-model="form.tipoArrendodamentoUnidadeMedida"
        class="col-12 col-md-4"
        :options="opcoes.enumArredondamento"
        :label="$t('modulos.conversor_unidade.formulario.arredondamento')"
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
      />
    </v-form>
    <div class="mt-4">
      <tabela-padrao
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="-1"
        sem-acoes
        sem-paginacao
        :mostrar-checkbox="false"
      >
        <!-- eslint-disable vue/valid-v-slot-->
        <template v-slot:item.valorConvertido="{ item }">
          <div class="alinhar-direita">
            {{ converterValor(item.item.valorConvertido) }}
          </div>
        </template>

        <template v-slot:item.valorConvertidoExponencial="{ item }">
          <div class="alinhar-direita">
            {{ item.item.valorConvertidoExponencial }}
          </div>
        </template>
      </tabela-padrao>
    </div>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.fechar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ConversorUnidadeService from '@common/services/cadastros/ConversorUnidadeService.js';
import { ConversorUnidadeModel } from '@common/models/cadastros/ConversorUnidadeModel.js';
import { InputUnidadeMedida } from '@components/inputs';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { EnumModel } from '@common/models/geral/EnumModel';
import helpers from '@/common/utils/helpers';
import _ from 'lodash'

export default {
  components: { InputUnidadeMedida },
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new ConversorUnidadeModel({}),
      opcoes: {
        enumArredondamento: [],
      },
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'unidadeMedidaPara.simbolo',
            text: this.$t('modulos.conversor_unidade.tabela.para'),
            sortable: false,
          },
          {
            value: 'valorConvertido',
            text: this.$t('modulos.conversor_unidade.tabela.valor'),
            sortable: false,
          },
          {
            value: 'valorConvertidoExponencial',
            text: this.$t('modulos.conversor_unidade.tabela.exponencial'),
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t(
        'modulos.conversor_unidade.validacoes.formulario_invalido'
      );
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form.validate()) this.listar();
      },
      deep: true,
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'UnidadeMedidaConversao', 'Listar');
  },
  async mounted() {
    await this.buscarEnumArredondamento();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.conversor_unidade.titulos.listagem')
    );
  },
  methods: {
    converterValor(v){
      return helpers.formatarMoeda(v).replace('R$', '').replace(' ', '')
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ConversorUnidadeService.listar(this.form.request)
        .then((res) => {
          res.data.unidadesMedidasConvertidas.forEach(um => {
            um.unidadeMedidaPara.simboloMin = um.unidadeMedidaPara.simbolo.toLowerCase()
          })
          this.tabela.dados = _.sortBy(res.data.unidadesMedidasConvertidas, 'unidadeMedidaPara.simboloMin');
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.conversor_unidade.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.$router.push({ name: 'dashboard' });
    },
    buscarEnumArredondamento: async function () {
      await EnumeradorService.buscar(
        'EnumTipoArrendodamentoUnidadeMedida'
      ).then((res) => {
        const enumArredondamento = res.map((e) => {
          return {
            value: e.value,
            text: e.text,
          };
        });
        this.opcoes.enumArredondamento = new EnumModel(
          enumArredondamento,
          'EnumTipoArrendodamentoUnidadeMedida'
        );
      });
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
